/**
 * Inservice Waiting Room
 * Inservice waiting-room API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AvailabilityTimeframeDto } from './availabilityTimeframeDto';


export interface AvailabilityDto { 
    id: string;
    label?: string;
    /**
     * Day in week in range of 0 to 6, 0 being Monday, 1 being Tuesday, etc.
     */
    day?: number;
    type: AvailabilityDto.TypeEnum;
    date?: string;
    timeframes: Array<AvailabilityTimeframeDto>;
}
export namespace AvailabilityDto {
    export type TypeEnum = 'date' | 'wday';
    export const TypeEnum = {
        Date: 'date' as TypeEnum,
        Wday: 'wday' as TypeEnum
    };
}


