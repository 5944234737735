/**
 * Inservice Waiting Room
 * Inservice waiting-room API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WaitingRoomStatus = 'active' | 'started' | 'in_progress' | 'expired' | 'finished' | 'incomplete' | 'canceled';

export const WaitingRoomStatus = {
    Active: 'active' as WaitingRoomStatus,
    Started: 'started' as WaitingRoomStatus,
    InProgress: 'in_progress' as WaitingRoomStatus,
    Expired: 'expired' as WaitingRoomStatus,
    Finished: 'finished' as WaitingRoomStatus,
    Incomplete: 'incomplete' as WaitingRoomStatus,
    Canceled: 'canceled' as WaitingRoomStatus
};

