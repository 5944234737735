/**
 * Inservice Waiting Room
 * Inservice waiting-room API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';

// @ts-ignore
import { CreateWaitingRoomConfigDto } from '../model/createWaitingRoomConfigDto';
// @ts-ignore
import { UpdateWaitingRoomConfigDto } from '../model/updateWaitingRoomConfigDto';
// @ts-ignore
import { UploadedPhotoDto } from '../model/uploadedPhotoDto';
// @ts-ignore
import { WaitingRoomConfigDto } from '../model/waitingRoomConfigDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomConfigService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
  * @param consumes string[] mime-types
  * @return true: consumes contains 'multipart/form-data', false: otherwise
  */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param xTENANTID 
   * @param createWaitingRoomConfigDto 
   */
  public async waitingRoomConfigControllerCreate(xTENANTID: string, createWaitingRoomConfigDto: CreateWaitingRoomConfigDto, ): Promise<WaitingRoomConfigDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerCreate.');
    }
    if (createWaitingRoomConfigDto === null || createWaitingRoomConfigDto === undefined) {
      throw new Error('Required parameter createWaitingRoomConfigDto was null or undefined when calling waitingRoomConfigControllerCreate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config`,
      data: createWaitingRoomConfigDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   */
  public async waitingRoomConfigControllerFindAll(xTENANTID: string, ): Promise<Array<WaitingRoomConfigDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerFindAll.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomConfigControllerFindOne(xTENANTID: string, id: string, ): Promise<WaitingRoomConfigDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerFindOne.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomConfigControllerFindOne.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomConfigControllerRemove(xTENANTID: string, id: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerRemove.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomConfigControllerRemove.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomConfigControllerRemoveLogo(xTENANTID: string, id: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerRemoveLogo.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomConfigControllerRemoveLogo.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/logo`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   * @param updateWaitingRoomConfigDto 
   */
  public async waitingRoomConfigControllerUpdate(xTENANTID: string, id: string, updateWaitingRoomConfigDto: UpdateWaitingRoomConfigDto, ): Promise<WaitingRoomConfigDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerUpdate.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomConfigControllerUpdate.');
    }
    if (updateWaitingRoomConfigDto === null || updateWaitingRoomConfigDto === undefined) {
      throw new Error('Required parameter updateWaitingRoomConfigDto was null or undefined when calling waitingRoomConfigControllerUpdate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      data: updateWaitingRoomConfigDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   * @param photo 
   */
  public async waitingRoomConfigControllerUploadLogo(xTENANTID: string, id: string, photo: Blob, ): Promise<UploadedPhotoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomConfigControllerUploadLogo.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomConfigControllerUploadLogo.');
    }
    if (photo === null || photo === undefined) {
      throw new Error('Required parameter photo was null or undefined when calling waitingRoomConfigControllerUploadLogo.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }
      // to determine the Content-Type header
      const consumes: string[] = [
        'multipart/form-data'
      ];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }
    if (photo !== undefined) {
      localVarFormParams = localVarFormParams.append('photo', <any>photo) as any || localVarFormParams;
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room-config/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/logo`,
      data: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

}
